<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "UnsyncedOrdersRouter",
});
</script>

<style scoped></style>
